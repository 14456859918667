<template>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="100px" :model="form">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="原图" prop="resource" :rules="[{required: true, message: '请上传图片'}]">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                                   accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                                   :headers="{authorization: $root.authorization}">
                            <img v-if="form.resource" :src="`${$root.env.CDN_URL}/${form.resource}`"
                                 style="max-width: 200px">
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="目标宽度" prop="width" :rules="[{required: true, message: '请输入正整数'}]">
                        <el-slider v-model="form.width" :max="100" :min="50" :disabled="loading"></el-slider>
                    </el-form-item>
                    <el-form-item label="目标高度" prop="height" :rules="[{required: true, message: '请输入正整数'}]">
                        <el-slider v-model="form.height" :max="100" :min="50" :disabled="loading"></el-slider>
                    </el-form-item>
                    <el-form-item label="目标颜色" prop="k" :rules="[{required: true, message: '请输入正整数'}]">
                        <el-slider v-model="form.k" :max="100" :min="20" :disabled="loading"></el-slider>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" :loading="loading" @click="cluster">合并</el-button>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="合并后" prop="cluster" v-loading="loading">
                        <el-image v-if="form.cluster" :src="`data:image/png;base64,${form.cluster}`"></el-image>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";

export default {
    name: "Tools",
    data() {
        return {
            dialog_opened: false, loading: false,
            form: {
                resource: '',
                width: 100,
                height: 100,
                k: 50,
                cluster: '',
            }
        }
    },
    methods: {
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = false;
        },
        open() {
            this.dialog_opened = true;
        },
        handleProgress() {
            this.loading = true;
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.resource = res.data.name
        },
        cluster() {
            this.loading = true;
            axios.post(`/cms/v1/cluster/image`, this.form).then(res => {
                this.form.cluster = res.data.data;
                this.loading = false;
            })
        },
    },
}
</script>

<style scoped>

</style>