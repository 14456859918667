<template>
    <el-dialog v-model="opened" width="80%" :before-close="close">
        <div>
            <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
        </div>
        <el-table :data="data" v-loading="loading" style="margin-top: 10px">
            <el-table-column prop="id" width="150" label="ID"></el-table-column>
            <el-table-column prop="x" width="80" label="X坐标"></el-table-column>
            <el-table-column prop="y" width="80" label="Y坐标"></el-table-column>
            <el-table-column prop="w" width="80" label="宽度"></el-table-column>
            <el-table-column prop="h" width="80" label="高度"></el-table-column>
            <el-table-column label="图片">
                <template #default="scope">
                    <vue-draggable-next v-model="scope.row.images" :animation="300" handle=".handle"
                                        @change="sort(scope.row)" class="gallery">
                        <template v-for="i in scope.row.images">
                            <div class="item handle" style="position: relative;" @click="openDialog1(scope.row, i)">
                                <el-image style="width: 100px;height: 100px;" lazy
                                          :src="`${$root.env.CDN_URL}/${i.thumbnail}`"></el-image>
                                <el-icon size="20" @click.stop="remove1(scope.row, i)"
                                         style="position: absolute;color: var(--el-color-danger); top: 0;right: 0;">
                                    <CircleCloseFilled/>
                                </el-icon>
                            </div>
                        </template>
                        <el-card class="item" shadow="never" @click="openDialog1(scope.row)">
                            <Plus style="color: var(--el-text-color-secondary)"/>
                        </el-card>
                    </vue-draggable-next>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作">
                <template #default="scope">
                    <el-button type="success" circle icon="Top" :loading="loading"
                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading"
                               :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                    <el-button :disabled="loading" @click="openDialog(scope.row)" circle icon="Edit"></el-button>
                    <el-button type="danger" :disabled="loading" @click="remove(scope.row)" circle
                               icon="Delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" @click="close">关闭</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form label-width="100px" ref="form" :model="form">
            <el-form-item label="X坐标" prop="x" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.x" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="Y坐标" prop="y" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.y" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="宽度" prop="w" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.w" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="高度" prop="h" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.h" :disabled="loading"></el-input-number>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened1" :before-close="reset1">
        <el-form ref="form1" label-width="100px" :model="form1">
            <el-form-item label="家园图片" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
                <el-radio-group v-model="form1.image_id" :disabled="loading">
                    <el-radio v-for="i in dataFilter" :label="i.id" style="margin: 30px 0">
                        <el-image lazy style="width: 100px;"
                                  :src="`${$root.env.CDN_URL}/${i.thumbnail}`"></el-image>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="封面" prop="thumbnail" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleThumbnailSuccess"
                           accept="image/*" :data="{prefix: 'scene'}" :onProgress="handleProgress"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form1.thumbnail" :src="`${$root.env.CDN_URL}/${form1.thumbnail}`" style="width: 100px">
                    <el-button v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset1">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit1">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessageBox} from 'element-plus';
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "Point",
    props: {
        image_list: Array,
    },
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, scene: null, opened: false, data: [], point_image: [],
            editing: null, dialog_opened: false,
            form: {
                scene_id: '',
                x: null,
                y: null,
                w: null,
                h: null,
            },
            editing1: null, dialog_opened1: false, point: null,
            form1: {
                point_id: '',
                thumbnail: '',
                image_id: '',
            },
        }
    },
    methods: {
        init(scene) {
            this.scene = scene;
            this.loading = this.opened = true;
            axios.all([
                axios.get(`/cms/v1/point`, {params: {scene_id: scene.id}}).then(res => {
                    this.data = res.data.data.pointList;
                }),
                axios.get(`/cms/v1/point-image`).then(res => {
                    this.point_image = res.data.data.pointImageList;
                }),
            ]).then(_ => {
                this.data.forEach(d => {
                    d.images = [];
                    this.point_image.forEach(p => {
                        if (d.id === p.point_id) {
                            d.images.push(p);
                        }
                    })
                });
                this.loading = false;
            })
        },
        close() {
            this.loading = this.opened = false;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/point/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        this.form.scene_id = this.scene.id;
                        axios.post(`/cms/v1/point`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.sort();
                            this.reset();
                        })
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/point/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.sort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.sort();
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/point`, {sort}).then(res => {
                this.loading = false;
            });
        },
        openDialog1(point, item) {
            this.dialog_opened1 = true;
            this.point = point;
            this.$nextTick(_ => {
                if (item) {
                    this.editing1 = item;
                    update(this.form1, item);
                }
            })
        },
        reset1() {
            this.$refs.form1.resetFields();
            this.loading = this.dialog_opened1 = false;
            this.editing1 = null;
        },
        submit1() {
            this.$refs.form1.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing1) {
                        axios.put(`/cms/v1/point-image/${this.editing1.id}`, this.form1).then(res => {
                            update(this.editing1, res.data.data);
                            this.reset1();
                        })
                    } else {
                        this.form1.point_id = this.point.id;
                        axios.post(`/cms/v1/point-image`, this.form1).then(res => {
                            this.point.images.push(res.data.data);
                            this.sort1(this.point);
                            this.reset1();
                        })
                    }
                }
            })
        },
        remove1(point, item) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/point-image/${item.id}`).then(_ => {
                    point.images.splice(point.images.indexOf(item), 1);
                    this.loading = false;
                })
            })
        },
        sort1(row) {
            const sort = [];
            row.images.forEach(i => {
                sort.push(i.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/point-image`, {sort}).then(res => {
                this.loading = false;
            })
        },
        handleThumbnailSuccess(res) {
            this.loading = false;
            this.form1.thumbnail = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
    },
    computed: {
        dataFilter() {
            return this.image_list.filter(i => {
                let valid = true;
                this.point_image.forEach(p => {
                    if (p.image_id === i.id) {
                        valid = false;
                    }
                })
                return i.module === 4 && i.status === 1 && valid || (this.editing1 && this.editing1.image_id === i.id);
            })
        }
    }
}
</script>

<style scoped>
.gallery {
    display: flex;
}

.gallery .item {
    margin: 0 5px;
    width: 100px;
    height: 100px;
    cursor: pointer;
}
</style>