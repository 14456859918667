<template>
    <div style="display: flex">
        <el-select v-model="form.country" style="width: 100px" @change="init" filterable
                   :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'">
                {{ c.en_name + ' (' + c.code + ')' }}
            </el-option>
        </el-select>
        <el-select style="width: 100px" placeholder="分类" filterable v-model="form.category_id"
                   :disabled="loading" clearable>
            <el-option v-for="c in category_list" :value="c.id" :label="c.i18n_name.ZH_HANS"></el-option>
        </el-select>
        <el-select style="width: 100px" placeholder="模块" v-model="form.module" :disabled="loading" clearable>
            <el-option v-for="(v, k) in image_module_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-input style="width: 100px" placeholder="ID" v-model="form.id" :disabled="loading"></el-input>
        <el-select v-model="form.type" placeholder="类型" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="(v, k) in image_type_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-model="form.video" placeholder="激励视频" clearable style="width: 100px" :disabled="loading">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>
        <el-select style="width: 100px" placeholder="作者" filterable v-model="form.author"
                   :disabled="loading" clearable>
            <el-option v-for="c in author_list" :value="c"></el-option>
        </el-select>
        <el-select v-model="form.magic" placeholder="问号图" clearable style="width: 100px" :disabled="loading">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>
        <el-select v-model="form.rookie" placeholder="新手图" clearable style="width: 100px" :disabled="loading">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>
        <date-range-picker v-model="form" :disabled="loading" clearable start-placeholder="上线开始时间"
                           end-placeholder="上线结束时间"></date-range-picker>
    </div>
    <downloadable-table :data="dataPage" v-loading="loading" style="margin-top: 20px" @sort-change="sortChange"
                        :summaryMethod="summaryMethod" show-summary title="素材评分" :complete="dataFilter">
        <el-table-column label="假ID" width="100" prop="nickname"></el-table-column>
        <el-table-column label="预览图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="完成图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.env.CDN_URL}/${scope.row.origin_colored}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="分类" width="80" prop="category" sortable>
            <template #default="scope">
                <template v-for="c in scope.row.categories">
                    <template v-for="cate in category_list">
                        <el-tag v-if="c === cate.id">{{ cate.i18n_name.ZH_HANS }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="模块" prop="module" width="80">
            <template #default="scope">
                {{ image_module_enum[scope.row.module] }}
            </template>
        </el-table-column>
        <el-table-column label="作者" width="80" prop="author"></el-table-column>
        <el-table-column label="激励" width="80" prop="level">
            <template #default="scope">
                {{ scope.row.video ? '是' : '否' }}
            </template>
        </el-table-column>
        <el-table-column label="上线时间" width="140" prop="create_date" sortable></el-table-column>
        <el-table-column label="色块" width="100" prop="cells"></el-table-column>
        <el-table-column label="3日价值">
            <el-table-column label="展示" prop="impression_3" width="100"></el-table-column>
            <el-table-column label="进入" prop="enter_3" width="100"></el-table-column>
            <el-table-column label="进入率" prop="enter_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.enter_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="完成率" prop="finish_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.finish_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="下载率" prop="download_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.download_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="分享率" prop="share_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.share_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="bomb使用" prop="bomb_rate_3" width="100"></el-table-column>
            <el-table-column label="bucket使用" prop="bucket_rate_3" width="100"></el-table-column>
            <el-table-column label="magic使用" prop="magic_rate_3" width="100"></el-table-column>
            <el-table-column label="人均进度" prop="colored_3" width="100">
                <template #default="scope">
                    {{ scope.row.colored_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="人均耗时" prop="duration_3" width="100">
                <template #default="scope">
                    {{ (scope.row.duration_3 / scope.row.enter_3).toFixed(2) }}秒
                </template>
            </el-table-column>
        </el-table-column>
    </downloadable-table>
    <div style="margin-top: 20px;text-align: center">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
</template>

<script>
import axios from "ts-axios-new";
import DownloadableTable from "../base/DownloadableTable";
import DateRangePicker from "../base/DateRangePicker";

export default {
    name: "Score",
    components: {DownloadableTable, DateRangePicker},
    data() {
        return {
            loading: false, category_list: [], author_list: [], data: [], page: 1, size: 20, image_list: [],
            daily: [], rookie: [],
            image_module_enum: {0: '图库', 1: 'DAILY', 2: '发现页', 3: '臻选', 4: '家园', 5: '收集活动'},
            image_type_enum: {1: '普通', 2: 'GIF', 3: '彩绘', 4: '3d', 7: '镜像', 8: '快速涂色'},
            form: {
                country: null,
                category_id: null,
                module: null,
                enter: 1,
                finish: 2,
                download: 4,
                share: 5,
                id: null,
                key: null,
                author: null,
                type: null,
                video: null,
                magic: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/metric`, {params: {country: this.form.country}}).then(res => {
                    this.data = res.data.data.metricList;
                }),
            ]).then(_ => {
                const image_map = {};
                this.image_list.forEach(m => {
                    image_map[m.id] = m;
                });
                this.data.forEach(d => {
                    if (image_map[d.image_id]) {
                        d.type = image_map[d.image_id].type;
                        d.categories = image_map[d.image_id].categories;
                        d.module = image_map[d.image_id].module;
                        d.online_date = image_map[d.image_id].online_date;
                        d.cells = image_map[d.image_id].cells;
                        d.author = image_map[d.image_id].author;
                        d.video = image_map[d.image_id].video;
                        d.magic = image_map[d.image_id].magic;
                        d.thumbnail = image_map[d.image_id].thumbnail;
                        d.origin_colored = image_map[d.image_id].origin_colored;
                        d.nickname = image_map[d.image_id].nickname;
                    }
                    d.enter_rate_3 = d.impression_3 ? parseInt(d.enter_3 / d.impression_3 * 10000) / 100.0 : null;
                    d.finish_rate_3 = parseInt(d.finish_3 / d.enter_3 * 10000) / 100.0 || 0;
                    d.bomb_rate_3 = parseInt(d.bomb_3 / d.enter_3 * 100) / 100.0 || 0;
                    d.bucket_rate_3 = parseInt(d.bucket_3 / d.enter_3 * 100) / 100.0 || 0;
                    d.magic_rate_3 = parseInt(d.magic_3 / d.enter_3 * 100) / 100.0 || 0;
                    d.download_rate_3 = parseInt(d.download_3 / d.finish_3 * 10000) / 100.0 || 0;
                    d.share_rate_3 = parseInt(d.share_3 / d.finish_3 * 10000) / 100.0 || 0;
                    d.colored_rate_3 = parseInt(d.colored_3 / d.enter_3 / d.cells * 10000) / 100.0 || 0;
                    d.enter_rate_7 = d.impression_7 ? parseInt(d.enter_7 / d.impression_7 * 10000) / 100.0 : null;
                    d.finish_rate_7 = parseInt(d.finish_7 / d.enter_7 * 100) / 100.0 || 0;
                    d.magic_rate_7 = parseInt(d.magic_7 / d.enter_7 * 100) / 100.0 || 0;
                    d.bomb_rate_7 = parseInt(d.bomb_7 / d.enter_7 * 100) / 100.0 || 0;
                    d.bucket_rate_7 = parseInt(d.bucket_7 / d.enter_7 * 100) / 100.0 || 0;
                    d.download_rate_7 = parseInt(d.download_7 / d.finish_7 * 10000) / 100.0 || 0;
                    d.share_rate_7 = parseInt(d.share_7 / d.finish_7 * 10000) / 100.0 || 0;
                });
                this.sortChange({});
                this.loading = false;
            })
        },
        resort() {
            this.data.forEach(d => {
                d.score = this.form.enter * d.enter_7 + this.form.finish * d.finish_7 / d.enter_7 + this.form.download * d.download_7 / d.finish_7 + this.form.share * d.share_7 / d.finish_7;
            });
            this.data.sort(function (a, b) {
                return b.score - a.score;
            })
        },
        sortChange({column, prop, order}) {
            if (prop) {
                this.data.sort(function (a, b) {
                    return order === 'descending' ? (b[prop] < a[prop] ? -1 : 1) : (a[prop] < b[prop] ? -1 : 1);
                })
            } else {
                this.data.sort(function (a, b) {
                    return b.enter_rate_3 - a.enter_rate_3;
                })
            }
        },
        summaryMethod({columns, data}) {
            const summary = [];
            let enter = 0, impression = 0, finish = 0, download = 0, share = 0, colored = 0, cells = 0,
                duration = 0, t = 0, bucket = 0, bomb = 0, magic = 0;
            this.dataFilter.forEach(d => {
                enter += d.enter_3;
                impression += d.impression_3;
                finish += d.finish_3;
                download += d.download_3;
                share += d.share_3;
                colored += d.colored_3;
                bucket += d.bucket_3;
                bomb += d.bomb_3;
                magic += d.magic_3;
                cells += d.cells;
                duration += d.duration_3;
                t += 1;
            });
            columns.forEach((c, i) => {
                if (c.property === 'enter_3') {
                    summary[i] = Math.round(enter / t);
                } else if (c.property === 'impression_3') {
                    summary[i] = Math.round(impression / t);
                } else if (c.property === 'enter_rate_3') {
                    summary[i] = parseInt(enter / impression * 10000) / 100 + '%';
                } else if (c.property === 'finish_rate_3') {
                    summary[i] = parseInt(finish / enter * 10000) / 100 + '%';
                } else if (c.property === 'download_rate_3') {
                    summary[i] = parseInt(download / finish * 10000) / 100 + '%';
                } else if (c.property === 'share_rate_3') {
                    summary[i] = parseInt(share / finish * 10000) / 100 + '%';
                } else if (c.property === 'bucket_rate_3') {
                    summary[i] = (bucket / enter).toFixed(2)
                } else if (c.property === 'bomb_rate_3') {
                    summary[i] = (bomb / enter).toFixed(2);
                } else if (c.property === 'magic_rate_3') {
                    summary[i] = (magic / enter).toFixed(2);
                } else if (c.property === 'colored_3') {
                    summary[i] = (colored / enter / cells * t * 100).toFixed(2) + '%';
                } else if (c.property === 'duration_3') {
                    summary[i] = (duration / enter).toFixed(2) + '秒';
                } else if (c.property === 'score_3') {
                    summary[i] = (duration / impression).toFixed(2);
                }
            });
            return summary;
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/image`).then(res => {
                this.image_list = res.data.data.imageList;
                this.image_list.forEach(i => {
                    if (i.author && !this.author_list.includes(i.author))
                        this.author_list.push(i.author);
                })
            }),
            axios.get('/cms/v1/category').then(res => {
                this.category_list = res.data.data.categoryList;
            }),
            axios.get('/cms/v1/rookie').then(res => {
                this.rookie = res.data.data.imageList;
            }),
            axios.get('/cms/v1/daily').then(res => {
                this.daily = res.data.data.imageList;
            }),
        ]).then(_ => {
            const daily = {}
            this.daily.forEach(d => {
                daily[d.image_id] = d.date;
            });
            this.image_list.forEach(i => {
                if (daily[i.id]) {
                    i.online_date = daily[i.id];
                }
            });
            this.init();
        })
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                let valid = d.impression_3 > 100;
                valid &= !this.form.id || d.image_id.includes(this.form.id) || (d.nickname + '').includes(this.form.id);
                valid &= !this.form.author || d.author === this.form.author;
                valid &= !this.form.type || d.type === this.form.type;
                valid &= this.form.module == null || this.form.module === '' || d.module === this.form.module;
                valid &= ![true, false].includes(this.form.video) || d.video === this.form.video;
                valid &= ![true, false].includes(this.form.magic) || d.magic === this.form.magic;
                valid &= !this.form.start && !this.form.end || d.online_date >= this.form.start && d.online_date <= this.form.end;
                valid &= !this.form.category_id || d.categories && d.categories.includes(this.form.category_id);
                if (valid) {
                    if (this.form.rookie === true) {
                        valid = false;
                        this.rookie.forEach(r => {
                            if (r.image_id === d.image_id) {
                                valid = true;
                            }
                        })
                    } else if (this.form.rookie === false) {
                        this.rookie.forEach(r => {
                            if (r.image_id === d.image_id) {
                                valid = false;
                            }
                        })
                    }
                }
                return valid;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    }
}
</script>

<style scoped>

</style>