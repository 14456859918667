<template>
    <div>
        <el-button type="primary" :loading="loading" @click="open()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="主键" prop="key" width="100"></el-table-column>
        <el-table-column label="名称" prop="i18n_name.EN" width="100"></el-table-column>
        <el-table-column label="活跃" prop="active" width="100">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" :inactive-value="false"
                           :active-value="true" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button :disabled="loading" @click="open(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="主键" prop="key" :rules="[{required: true, message: '请输入主键'}]">
                <el-input v-model="form.key" :disabled="loading || editing"></el-input>
            </el-form-item>
            <el-divider/>
            <el-form-item label="简体中文" prop="i18n_name.ZH_HANS" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.ZH_HANS" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文" prop="i18n_name.ZH_HANT" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.ZH_HANT" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="英语" prop="i18n_name.EN" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.EN" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="法语" prop="i18n_name.FR" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.FR" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="印地语" prop="i18n_name.HI" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.HI" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="意大利语" prop="i18n_name.IT" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.IT" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="阿拉伯语" prop="i18n_name.AR" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.AR" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="西班牙语" prop="i18n_name.ES" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.ES" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="葡萄牙语" prop="i18n_name.PT" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.PT" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="德语" prop="i18n_name.DE" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.DE" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="希腊语" prop="i18n_name.EL" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.EL" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="印尼语" prop="i18n_name.ID" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.ID" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="韩语" prop="i18n_name.KO" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.KO" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="泰语" prop="i18n_name.TH" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.TH" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="荷兰语" prop="i18n_name.NL" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.NL" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="土耳其语" prop="i18n_name.TR" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.TR" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="波兰语" prop="i18n_name.PL" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.PL" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="越南语" prop="i18n_name.VI" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.VI" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="芬兰语" prop="i18n_name.FI" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.FI" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="俄语" prop="i18n_name.RU" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.RU" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="匈牙利语" prop="i18n_name.HU" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.HU" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="日语" prop="i18n_name.JA" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.JA" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="挪威语" prop="i18n_name.NO" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.NO" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="瑞典语" prop="i18n_name.SV" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.SV" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="乌克兰语" prop="i18n_name.UK" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.UK" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="马来语" prop="i18n_name.MS" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.MS" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="希伯来语" prop="i18n_name.IW" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.IW" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="罗马尼亚语" prop="i18n_name.RO" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.RO" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="塔加洛语" prop="i18n_name.TL" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.i18n_name.TL" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";

export default {
    name: "Category",
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            form: {
                active: false,
                key: '',
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                resource: '',
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/category`).then(res => {
                this.data = res.data.data.categoryList;
                this.loading = false;
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        open(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/category/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        });
                    } else {
                        axios.post(`/cms/v1/category`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/category`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/category/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>