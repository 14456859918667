<template>
    <div style="display: flex;justify-content: space-between">
        <el-button type="primary" :disabled="loading" @click="openDialog">新建</el-button>
        <el-select v-model="form.label" :disabled="loading" @change="formatData">
            <el-option v-for="l in labels" :value="l" :label="`方案${l}`"></el-option>
        </el-select>
    </div>
    <template v-for="d in data">
        <el-card style="margin-top: 5px" shadow="never" :header="`第${d.day}天`" v-if="d" v-loading="loading">
            <vue-draggable-next v-model="d.images" :animation="300" handle=".handle" @change="resort(d.images)">
                <template v-for="rookie in d.images">
                    <div class="handle" style="display: inline-block;position: relative">
                        <el-image style="margin: 5px;height: 160px"
                                  :src="`${$root.env.CDN_URL}/${rookie.thumbnail}`"></el-image>
                        <el-icon @click="remove(rookie)"
                                 style="position: absolute; top: 5px; right: 5px; cursor: pointer">
                            <CircleClose/>
                        </el-icon>
                    </div>
                </template>
            </vue-draggable-next>
        </el-card>
    </template>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="天数" prop="day" :rules="[{required: true, message: '输入天数', type: 'number'}]">
                <el-input-number v-model="form.day" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="图片" prop="images" :rules="[{required: true, message: '请选择图片'}]">
                <template v-for="i in form.images">
                    <template v-for="image in image_list">
                        <el-image v-if="i === image.id" style="width: 160px"
                                  :src="`${$root.env.CDN_URL}/${image.thumbnail}`"></el-image>
                    </template>
                </template>
            </el-form-item>
        </el-form>
        <el-input v-model="query" prefix-icon="Search"></el-input>
        <el-table style="margin-top: 10px" v-loading="loading" :data="dataFilter">
            <el-table-column label="预览图" prop="prefix" width="230">
                <template #default="scope">
                    <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"
                              style="width: 100px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="假ID" prop="nickname" width="100"></el-table-column>
            <el-table-column label="类型" prop="type" width="60">
                <template #default="scope">
                    {{ image_type_enum[scope.row.type] }}
                </template>
            </el-table-column>
            <el-table-column label="色块" prop="cells" width="60"></el-table-column>
            <el-table-column label="难度" prop="difficulty" width="60">
                <template #default="scope">
                    <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                        L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="60">
                <template #default="scope">
                    <template v-for="c in scope.row.categories">
                        <template v-for="cc in category_list">
                            <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="作者" prop="author" width="100"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="scope">
                    <el-button type="primary" @click="addImage(scope.row)" :disabled="loading">添加</el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "Rookie",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], label: [], labels: [0, 1, 2, 3], image_list: [], category_list: [],
            dialog_opened: false, rookie: [], query: '',
            image_tag_enum: {
                1: 'Special',
                2: 'Wallpaper',
                3: 'Collection',
                4: 'Artist',
                5: 'Music',
                6: 'Magic',
                7: 'Hot'
            },
            image_type_enum: {1: '普通', 2: 'GIF', 3: '彩绘', 4: '3d'},
            form: {
                label: 0,
                day: 0,
                images: [],
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/rookie`).then(res => {
                this.rookie = res.data.data.imageList;
            }).then(_ => {
                this.loading = false;
                this.formatData();
            })
        },
        openDialog() {
            this.query = '';
            this.dialog_opened = true;
        },
        formatData() {
            this.label = [];
            this.data = [];
            this.rookie.forEach(r => {
                if (!this.label.includes(r.label)) {
                    this.label.push(r.label);
                }
                if (r.label === this.form.label) {
                    if (!this.data[r.day]) {
                        this.data[r.day] = {day: r.day, images: []}
                    }
                    this.image_list.forEach(i => {
                        if (i.id === r.image_id) {
                            r.thumbnail = i.thumbnail;
                        }
                    })
                    this.data[r.day].images.push(r);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.images = [];
            this.dialog_opened = this.loading = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    const reqs = [];
                    this.form.images.forEach(i => {
                        const data = {day: this.form.day, label: this.form.label, image_id: i};
                        reqs.push(axios.post(`/cms/v1/rookie`, data).then(res => {
                            this.rookie.push(res.data.data);
                        }));
                    });
                    axios.all(reqs).then(_ => {
                        this.init();
                        this.reset();
                    })
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`cms/v1/rookie/${row.id}`).then(res => {
                this.init()
            });
        },
        addImage(image) {
            this.form.images.push(image.id);
        },
        resort(images) {
            const sort = [];
            images.forEach(i => {
                sort.push(i.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/rookie`, {sort}).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/image`).then(res => {
                this.image_list = res.data.data.imageList;
            }),
            axios.get('/cms/v1/category').then(res => {
                this.category_list = res.data.data.categoryList;
            }),
        ]).then(_ => {
            this.loading = false;
            this.init();
        })
    },
    computed: {
        dataFilter() {
            if (!this.query) {
                return [];
            }
            return this.image_list.filter(d => {
                let valid = d.status === 1 && (d.id === this.query || (d.nickname + '') === this.query);
                if (valid) {
                    this.data.forEach(dd => {
                        if (dd.image_id === d.id) {
                            valid = false;
                        }
                    })
                }
                return valid
            });
        }
    }
}
</script>

<style scoped>

</style>