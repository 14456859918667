<template>
    <div>
        <el-button type="primary" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="封面" prop="cover" width="150">
            <template #default="scope">
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.cover}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="image_id" width="150">
            <template #default="scope">
                <template v-for="i in image_list">
                    <el-image v-if="i.id === scope.row.image_id" lazy style="width: 100px"
                              :src="`${$root.env.CDN_URL}/${i.thumbnail}`"></el-image>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="ID" prop="image_id" width="150"></el-table-column>
        <el-table-column label="假ID" width="100">
            <template #default="scope">
                <template v-for="i in image_list">
                    <span v-if="i.id === scope.row.image_id">{{ i.nickname }}</span>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="名称" prop="i18n_name.EN" width="200"></el-table-column>
        <el-table-column label="最小天数" prop="day" width="100"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" size="small" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="danger" size="small" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="假ID" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
                <el-select v-model="form.image_id" :disabled="loading" filterable>
                    <el-option v-for="i in image_list" :value="i.id" :label="i.nickname"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'chosen'}" :onProgress="handleProgress"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.cover" :src="`${$root.env.CDN_URL}/${form.cover}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="最小天数" prop="day">
                <el-input-number v-model="form.day" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessageBox} from 'element-plus';

export default {
    name: "Chosen",
    data() {
        return {
            loading: false, data: [], image_list: [], editing: null, dialog_opened: false,
            form: {
                image_id: '',
                cover: '',
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                day: 0,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/image`).then(res => {
                    this.image_list = res.data.data.imageList.filter(i => i.module === 3);
                }),
                axios.get(`/cms/v1/chosen`).then(res => {
                    this.data = res.data.data.chosenList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        sort() {
            this.data.sort(function (a, b) {
                return a.day - b.day;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.form.i18n_name = {
                EN: '',
                FR: '',
                HI: '',
                IT: '',
                AR: '',
                ES: '',
                PT: '',
                DE: '',
                EL: '',
                ZH_HANS: '',
                ZH_HANT: '',
                ID: '',
                KO: '',
                TH: '',
                NL: '',
                TR: '',
                PL: '',
                VI: '',
                FI: '',
                RU: '',
                HU: '',
                JA: '',
                NO: '',
                SV: '',
                UK: '',
                MS: '',
                IW: '',
                RO: '',
                TL: '',
            }
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/chosen/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/chosen`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.sort();
                            this.reset();
                        });
                    }
                }
            })
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.cover = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/chosen/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>