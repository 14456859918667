<template>
    <el-dialog v-model="dialog_opened" width="90%" :before-close="close" :close-on-click-modal="false">
        <el-row>
            <el-col :span="18" :xl="20">
                <el-form label-width="100px">
                    <el-form-item label="放大倍数">
                        <el-slider v-model="unit" :max="10" :min="1" :step="1" style="width: 500px"
                                   :disabled="loading"></el-slider>
                    </el-form-item>
                </el-form>
                <div v-loading="loading" ref="hooper" v-if="image" style="justify-content: center;display: flex">
                    <div style="position: relative;" id="rectangle">
                        <canvas ref="colored" id="colored"
                                :style="{width: width * unit + 'px', height: height * unit + 'px'}"></canvas>
                        <canvas ref="canvas" id="region"
                                style="position: absolute;top: 0;left: 0" :width="width" :height="height"
                                :style="{width: width * unit + 'px', height: height * unit + 'px'}"></canvas>
                    </div>
                </div>
            </el-col>
            <el-col :span="6" :xl="4">
                <el-radio-group v-model="mode" @change="clean"
                                :disabled="loading || image && [1].includes(image.status)">
                    <el-radio :label="1">改顺序</el-radio>
                    <el-radio :label="2">改颜色</el-radio>
                </el-radio-group>
                <template v-if="mode===1">
                    <div>
                        <el-button type="primary" @click="tryToColor" v-if="play===0">模拟涂色</el-button>
                        <el-button type="primary" @click="pauseColor" v-else-if="play===1">暂停涂色</el-button>
                        <el-button type="primary" @click="continueColor" v-else-if="play===2">继续涂色</el-button>
                        <el-button type="success" @click="tryToColor" v-if="play>0">重新涂色</el-button>
                    </div>
                    <h3>色盘</h3>
                    <div>
                        <el-scrollbar :max-height="height * unit - 150">
                            <vue-draggable-next v-model="groups" :animation="300" handle=".handle" @change="sorted"
                                                style="display: flex;flex-wrap: wrap;">
                                <div class="handle" v-for="(g, i) in groups" :class="changed.includes(g)?'active': ''">
                                    <div class="circle" @click="loading?null:changeActive(i)"
                                         :style="{backgroundColor: g}"
                                         :class="(isDark(g)?'dark':'light') + (active === i?' active':'')">
                                        {{ i + 1 }}
                                    </div>
                                </div>
                            </vue-draggable-next>
                        </el-scrollbar>
                    </div>
                </template>
                <template v-else-if="mode===2">
                    <div>
                        <el-color-picker v-model="color" :disabled="!color || loading"
                                         @change="changeColor"></el-color-picker>
                        {{ color }}
                    </div>
                    <h3>色盘</h3>
                    <div>
                        <el-scrollbar :max-height="height * unit - 150">
                            <div style="display: flex;flex-wrap: wrap">
                                <div class="circle" v-for="(g, i) in groups" :style="{backgroundColor: g}"
                                     :class="(isDark(g)?'dark':'light') + (active === i?' active':'')"
                                     @click="loading?null:changeActive(i)">
                                    {{ i + 1 }}
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </template>
            </el-col>
        </el-row>
        <template #footer>
            <el-button text type="primary" @click="close" :loading="loading">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading"
                       :disabled="image && [1].includes(image.status)">确定
            </el-button>
        </template>
    </el-dialog>
</template>

<script>
import {VueDraggableNext} from 'vue-draggable-next'
import axios from "ts-axios-new";
import {update} from "../libs/utils";
import {getLocalObj, setLocalObj, cleanLocalObj} from "../libs/storage";

export default {
    name: "Region",
    components: {VueDraggableNext},
    data() {
        return {
            image: null, dialog_opened: false, loading: false, unit: 8, width: 0, height: 0, mode: 1, play: 0,
            index: 0, groups: [], image_data: null, active: -1, selected: [], color: null, color_changed: false,
            color_data: null, start: null, duration: 1000, changed: [], highlight: null, x0: 0, y0: 0, x1: 0, y1: 0,
            dragging: false,
        }
    },
    methods: {
        init(image) {
            this.image = image;
            this.dialog_opened = this.loading = true;
            const changed = getLocalObj(`/changed/${this.image.id}`, null);
            if (changed) {
                update(this.changed, changed);
            }
            const colored = new Image();
            colored.src = `${import.meta.env.VITE_CDN_URL}/${this.image.origin_colored}`;
            colored.crossOrigin = "Anonymous";
            colored.onload = _ => {
                const image_canvas = this.$refs.colored;
                this.width = image_canvas.width = colored.width;
                this.height = image_canvas.height = colored.height;
                const context = image_canvas.getContext('2d');
                context.clearRect(0, 0, image_canvas.width, image_canvas.height);
                context.drawImage(colored, 0, 0);

                const canvas = this.$refs.canvas;
                canvas.addEventListener('click', this.click);
                canvas.addEventListener('mousedown', this.mousedown);
                canvas.addEventListener('mousemove', this.mousemove);
                canvas.addEventListener('mouseup', this.mouseup);

                this.image_data = context.getImageData(0, 0, image_canvas.width, image_canvas.height).data;
                if (this.image.origin_region) {
                    const region = new Image();
                    region.src = `${import.meta.env.VITE_CDN_URL}/${this.image.origin_region}`;
                    region.crossOrigin = "Anonymous";
                    region.onload = _ => {
                        const region_canvas = document.createElement('canvas');
                        region_canvas.width = region.width;
                        region_canvas.height = region.height;
                        const context = region_canvas.getContext('2d');
                        context.drawImage(region, 0, 0);
                        const region_data = context.getImageData(0, 0, region_canvas.width, region_canvas.height).data;
                        const groups = {};
                        for (let i = 0; i < region_data.length; i += 4) {
                            if (region_data[i + 3]) {
                                const index = region_data[i] + region_data[i + 1] * 256 + region_data[i + 2] * 256 ** 2;
                                if (!groups[index]) {
                                    groups[index] = i;
                                }
                            }
                        }
                        Object.keys(groups).forEach(k => {
                            const i = groups[k];
                            const r = this.image_data[i];
                            const g = this.image_data[i + 1];
                            const b = this.image_data[i + 2];
                            const hex = `#${this.convHex(r)}${this.convHex(g)}${this.convHex(b)}`;
                            if (!this.groups.includes(hex)) {
                                this.groups.push(hex);
                            }
                        });
                    }
                    this.loading = false;
                } else {
                    for (let i = 0; i < this.image_data.length; i += 4) {
                        if (this.image_data[i + 3]) {
                            const r = this.image_data[i];
                            const g = this.image_data[i + 1];
                            const b = this.image_data[i + 2];
                            if (r === 255 && g === 255 && b === 255) {
                                continue
                            }
                            const hex = `#${this.convHex(r)}${this.convHex(g)}${this.convHex(b)}`;
                            if (!this.groups.includes(hex)) {
                                this.groups.push(hex);
                            }
                        }
                    }
                    const cache = getLocalObj(`/region/${this.image.id}`, null);
                    if (cache) {
                        this.groups.sort(function (a, b) {
                            return cache.indexOf(a) - cache.indexOf(b);
                        });
                    }
                    this.loading = false;
                }
                requestAnimationFrame(this.animate);
            }
        },
        changeColor() {
            const canvas = this.$refs.colored;
            const ctx = canvas.getContext('2d');
            const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let r = null, g = null, b = null;
            this.selected.forEach(index => {
                r = this.image_data[index];
                g = this.image_data[index + 1];
                b = this.image_data[index + 2];
                this.image_data[index] = image_data.data[index] = parseInt(this.color.slice(1, 3), 16);
                this.image_data[index + 1] = image_data.data[index + 1] = parseInt(this.color.slice(3, 5), 16);
                this.image_data[index + 2] = image_data.data[index + 2] = parseInt(this.color.slice(5, 7), 16);
                this.image_data[index + 3] = image_data.data[index + 3] = 255;
            });
            const hex = `#${this.convHex(r)}${this.convHex(g)}${this.convHex(b)}`;
            if (!this.groups.includes(this.color) && this.color !== '#FFFFFF') {
                this.groups.splice(this.groups.indexOf(hex), 0, this.color);
            }
            let remove = true;
            for (let i = 0; i < this.image_data.length; i += 4) {
                if (this.image_data[i] === r && this.image_data[i + 1] === g && this.image_data[i + 2] === b && this.image_data[i + 3] === 255) {
                    remove = false;
                    break;
                }
            }
            if (remove) {
                this.groups.splice(this.groups.indexOf(hex), 1);
            }
            this.clean();
            ctx.putImageData(image_data, 0, 0);
            this.color_changed = true;
        },
        convHex(int) {
            return int.toString(16).padStart(2, '0').toUpperCase();
        },
        close() {
            this.dialog_opened = this.loading = this.color_changed = false;
            this.mode = 1;
            this.play = this.index = 0;
            const canvas = this.$refs.colored;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const canvas1 = this.$refs.canvas;
            const ctx1 = canvas1.getContext('2d');
            ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
            this.$nextTick(_ => {
                this.image = this.image_data = this.color = this.color_data = null;
                this.active = -1;
            });
            cleanLocalObj(`/changed/${this.image.id}`);
            this.groups = [];
            this.changed = [];
        },
        submit() {
            this.loading = true;
            const region = document.createElement('canvas');
            region.width = this.width;
            region.height = this.height;
            const ctx = region.getContext('2d');
            const data = ctx.getImageData(0, 0, region.width, region.height);
            for (let i = 0; i < this.image_data.length; i += 4) {
                if (this.image_data[i + 3]) {
                    const r = this.image_data[i];
                    const g = this.image_data[i + 1];
                    const b = this.image_data[i + 2];
                    if (r === 255 && g === 255 && b === 255) {
                        continue
                    }
                    const hex = `#${this.convHex(r)}${this.convHex(g)}${this.convHex(b)}`;
                    const index = this.groups.indexOf(hex) + 1;
                    data.data[i] = index % 256;
                    data.data[i + 1] = Math.floor(index / 256);
                    data.data[i + 2] = Math.floor(index / 256 ** 2);
                    data.data[i + 3] = 255;
                }
            }
            ctx.putImageData(data, 0, 0);
            const form = new FormData();
            region.toBlob(blob => {
                form.append('file', blob, 'image.png')
                if (this.color_changed) {
                    this.$refs.colored.toBlob(b => {
                        form.append('colored', b, 'colored.png');
                        this.put(form);
                    })
                } else {
                    this.put(form);
                }
            })
        },
        put(form) {
            axios.put(`/cms/v1/region/${this.image.id}`, form).then(res => {
                update(this.image, res.data.data);
                this.image.status = 0;
                this.close();
            });
        },
        click(e) {
            if (this.dragging)
                return
            const canvas = this.$refs.canvas;
            const rect = canvas.getBoundingClientRect();
            const offset_x = rect.left;
            const offset_y = rect.top;
            const x = Math.round((e.clientX - 4 - offset_x) * canvas.width / canvas.clientWidth);
            const y = Math.round((e.clientY - 4 - offset_y) * canvas.height / canvas.clientHeight);
            const i = this.width * y * 4 + x * 4;
            const hex = `#${this.convHex(this.image_data[i])}${this.convHex(this.image_data[i + 1])}${this.convHex(this.image_data[i + 2])}`;
            if (this.mode === 1) {
                this.changeActive(this.groups.indexOf(hex));
            } else if (this.mode === 2) {
                if (this.active < 0) {
                    this.changeActive(this.groups.indexOf(hex));
                    this.getRegion(x, y, e.ctrlKey || e.metaKey);
                } else if (this.active === this.groups.indexOf(hex)) {
                    this.getRegion(x, y, e.ctrlKey || e.metaKey);
                }
            }
        },
        getRegion(x, y, reverse) {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const i = this.width * y * 4 + x * 4;
            this.color = null;
            if (i < 0 || this.image_data[i + 3] === 0)
                return
            const r = this.image_data[i];
            const g = this.image_data[i + 1];
            const b = this.image_data[i + 2];
            if (r === 255 && g === 255 && b === 255)
                return
            this._getDFRegion(r, g, b, x, y, reverse);
            if (this.selected.length === 0) {
                this.active = -1;
                this.color = null;
            }
            this.color = `#${this.convHex(r)}${this.convHex(g)}${this.convHex(b)}`;
        },
        _getDFRegion(r, g, b, x, y, reverse) {
            const i = this.width * y * 4 + x * 4;
            if (this.image_data[i + 3] === 0 || this.image_data[i] !== r || this.image_data[i + 1] !== g || this.image_data[i + 2] !== b || !reverse && this.selected.includes(i) || reverse && !this.selected.includes(i)) {
                return
            }
            if (reverse) {
                if (this.selected.includes(i))
                    this.selected.splice(this.selected.indexOf(i), 1);
            } else {
                if (!this.selected.includes(i))
                    this.selected.push(i);
            }
            if (x > 0) {
                this._getDFRegion(r, g, b, x - 1, y, reverse);
            }
            if (y > 0) {
                this._getDFRegion(r, g, b, x, y - 1, reverse);
            }
            if (x < this.width - 1) {
                this._getDFRegion(r, g, b, x + 1, y, reverse);
            }
            if (y < this.height - 1) {
                this._getDFRegion(r, g, b, x, y + 1, reverse);
            }
        },
        sorted(e) {
            if (e.moved && e.moved.oldIndex === this.active) {
                this.active = e.moved.newIndex;
            }
            this.play = this.index = 0;
            if (!this.changed.includes(this.groups[e.moved.newIndex])) {
                this.changed.push(this.groups[e.moved.newIndex]);
            }
            setLocalObj(`/region/${this.image.id}`, this.groups);
            setLocalObj(`/changed/${this.image.id}`, this.changed);
        },
        clean() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.color = null;
            this.active = -1;
            this.selected = [];
        },
        changeActive(i) {
            if (this.active === i) {
                this.active = -1
            } else {
                this.active = i;
            }
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.selected = [];
        },
        animate(currentTime) {
            if (!this.$refs.canvas)
                return
            if (!this.start) {
                this.start = currentTime;
            }
            if (this.play === 1) {
                requestAnimationFrame(this.animate);
                return
            }
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const elapsedTime = currentTime - this.start;
            const progress = (elapsedTime % this.duration) / this.duration;

            // 计算当前颜色的透明度
            const alpha = Math.abs(Math.sin(progress * Math.PI));
            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);

            if (this.active > -1 && this.mode === 1) {
                const color = this.groups[this.active];
                const {r, g, b} = this.getRGB(color);
                const luminosity = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

                for (let i = 0; i < this.image_data.length; i += 4) {
                    if (this.image_data[i + 3]) {
                        if (this.image_data[i] === r && this.image_data[i + 1] === g && this.image_data[i + 2] === b) {
                            data.data[i] = luminosity < 0.5 ? 255 : 0;
                            data.data[i + 1] = luminosity < 0.5 ? 255 : 0;
                            data.data[i + 2] = luminosity < 0.5 ? 255 : 0;
                            data.data[i + 3] = Math.round(alpha * 255);
                        }
                    }
                }
                ctx.putImageData(data, 0, 0);
            } else if (this.mode === 2 && this.selected.length > 0) {
                const {r, g, b} = this.getRGB(this.color);
                const luminosity = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
                this.selected.forEach(i => {
                    data.data[i] = luminosity < 0.5 ? 255 : 0;
                    data.data[i + 1] = luminosity < 0.5 ? 255 : 0;
                    data.data[i + 2] = luminosity < 0.5 ? 255 : 0;
                    data.data[i + 3] = Math.round(alpha * 255);
                });
                ctx.putImageData(data, 0, 0);
            }
            // 循环调用动画函数
            requestAnimationFrame(this.animate);
        },
        isDark(color) {
            const {r, g, b} = this.getRGB(color);
            // 计算颜色亮度
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            // 比较亮度和阈值
            return brightness < 128;
        },
        getRGB(color) {
            const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
            // 提取红、绿、蓝分量的值
            const r = parseInt(rgb[1], 16);
            const g = parseInt(rgb[2], 16);
            const b = parseInt(rgb[3], 16);
            return {r, g, b};
        },
        tryToColor() {
            this.play = 1;
            this.index = 0;
            const canvas = this.$refs.colored;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const canvas1 = this.$refs.canvas;
            const ctx1 = canvas1.getContext('2d');
            ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
            this.draw();
        },
        pauseColor() {
            this.play = 2;
        },
        continueColor() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.play = 1;
            this.draw();
        },
        draw() {
            setTimeout(_ => {
                if (this.play === 2 || this.play === 0)
                    return
                const {r, g, b} = this.getRGB(this.groups[this.index]);
                const canvas = this.$refs.colored;
                const ctx = canvas.getContext('2d');
                const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
                for (let i = 0; i < this.image_data.length; i += 4) {
                    if (this.image_data[i] === r && this.image_data[i + 1] === g && this.image_data[i + 2] === b && this.image_data[i + 3] === 255) {
                        data.data[i] = r;
                        data.data[i + 1] = g;
                        data.data[i + 2] = b;
                        data.data[i + 3] = 255;
                    }
                }
                ctx.putImageData(data, 0, 0);
                this.active = this.index;
                this.index++;
                if (this.index >= this.groups.length) {
                    this.play = 0;
                } else {
                    this.draw();
                }
            }, 400);
        },
        mousemove(e) {
            if (this.highlight) {
                this.dragging = true;
                const rectangle = document.getElementById('rectangle');
                const {x, y} = rectangle.getBoundingClientRect();
                this.x1 = e.clientX - 4 - x;
                this.y1 = e.clientY - 4 - y;
                const w = Math.abs(this.x1 - this.x0);
                const h = Math.abs(this.y1 - this.y0);
                this.highlight.style.width = w + 'px';
                this.highlight.style.height = h + 'px';
                this.highlight.style.left = Math.min(this.x0, this.x1) + 'px';
                this.highlight.style.top = Math.min(this.y0, this.y1) + 'px';
            } else if (this.mode === 2 && this.active > -1 && e.buttons === 1) {
                const rectangle = document.getElementById('rectangle');
                const {x, y} = rectangle.getBoundingClientRect();
                this.x0 = e.clientX - 4 - x;
                this.y0 = e.clientY - 4 - y;
                const highlight = this.highlight = document.createElement('div');
                highlight.id = 'highlight';
                rectangle.appendChild(highlight);
            }
        },
        mouseup(e) {
            if (this.highlight && this.active > -1) {
                const rectangle = document.getElementById('rectangle');
                rectangle.removeChild(this.highlight);
                this.highlight = null;
                const color = this.groups[this.active];
                const {r, g, b} = this.getRGB(color);
                const reverse = e.ctrlKey || e.metaKey;
                for (let x = Math.round(Math.min(this.x0, this.x1) / this.unit); x < Math.round(Math.max(this.x0, this.x1) / this.unit); x++) {
                    for (let y = Math.round(Math.min(this.y0, this.y1) / this.unit); y < Math.round(Math.max(this.y0, this.y1) / this.unit); y++) {
                        const i = this.width * y * 4 + x * 4;
                        if (this.image_data[i + 3] === 0 || this.image_data[i] !== r || this.image_data[i + 1] !== g || this.image_data[i + 2] !== b || !reverse && this.selected.includes(i) || reverse && !this.selected.includes(i)) {
                            continue
                        }
                        if (reverse) {
                            if (this.selected.includes(i))
                                this.selected.splice(this.selected.indexOf(i), 1);
                        } else {
                            if (!this.selected.includes(i))
                                this.selected.push(i);
                        }
                    }
                }
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                this.color = `#${this.convHex(r)}${this.convHex(g)}${this.convHex(b)}`;
                this.active = this.groups.indexOf(this.color);
            }
            if (this.dragging) {
                setTimeout(_ => {
                    this.dragging = false;
                }, 100);
            }
        },
    },
    mounted() {
    }
}
</script>

<style scoped>
.handle.active {
    background-color: #CEFFA8;
}

.circle {
    width: 40px;
    height: 40px;
    margin: 4px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    border: 1px solid var(--el-color-info);
    opacity: 1;
}

.circle.active {
    font-size: 18px;
    margin: 0;
    border: 5px solid var(--el-color-primary);
}

.circle.light {
    color: #606266;
}

.circle.dark {
    color: #EBEEF5
}

#colored, #region {
    image-rendering: pixelated;
}
</style>