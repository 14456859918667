<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table style="margin-top: 10px" v-loading="loading" :data="data">
        <el-table-column label="ID" width="150" prop="id"></el-table-column>
        <el-table-column label="图片" width="300px" prop="image">
            <template #default="scope">
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.image}`"
                          style="width: 250px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="上线" width="100px" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="动作" prop="type" width="200">
            <template #default="scope">
                {{ carousel_type_enum[scope.row.type] }}
            </template>
        </el-table-column>
        <el-table-column label="背景色" prop="color" width="100"></el-table-column>
        <el-table-column label="操作" width="250">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="动作" prop="type" :rules="[{required: true, message: '请选择动作'}]">
                <el-select v-model="form.type" :disabled="loading" @change="changeType">
                    <el-option v-for="(v, k) in carousel_type_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Collection" prop="data.collection_id"
                          :rules="[{required: true, message: '请选择collection'}]" v-if="form.type===1">
                <el-select v-model="form.data.collection_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in collection_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Jigsaw" prop="data.jigsaw_id"
                          :rules="[{required: true, message: '请选择jigsaw'}]" v-if="form.type===2">
                <el-select v-model="form.data.jigsaw_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in jigsaw_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="URL" prop="data.url" :rules="[{required: true, message: '请输入url'}]"
                          v-if="form.type===4">
                <el-input v-model="form.data.url" :disabled="loading" v-if="form.data"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="image" :rules="[{required: true, message: '请上传图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'carousel'}" :onProgress="handleProgress"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.image" :src="`${$root.env.CDN_URL}/${form.image}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="背景色" prop="color" :rules="[{required: true, message: '请选择背景色', validator}]">
                <el-color-picker v-model="form.color" :disabled="loading"></el-color-picker>
                <el-input v-model="form.color" :disabled="loading"
                          style="width: 100px;display: inline-block;margin-left: 20px"></el-input>
            </el-form-item>
            <el-form-item label="退回至" prop="back">
                <el-select v-model="form.back">
                    <el-option :value="0" label="图库页"></el-option>
                    <el-option :value="1" label="发现页"></el-option>
                    <el-option :value="2" label="活动页"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, deepcopy} from "../../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Carousel",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, image_list: [], collection_list: [],
            gallery_list: [], jigsaw_list: [], level_list: [], story_list: [],
            carousel_type_enum: {
                0: '进入当日Daily',
                1: '进入Collection',
                2: '进入Jigsaw',
                4: '打开FB',
            },
            form: {
                type: null,
                color: '',
                image: '',
                data: null,
                back: 0,
                active: false,
            },
        }
    },
    methods: {
        changeType() {
            if ([0].includes(this.form.type)) {
                this.form.data = null
            } else if (this.form.type === 1) {
                this.form.data = {
                    collection_id: '',
                }
            } else if (this.form.type === 3) {
                this.form.data = {
                    gallery_id: '',
                }
            } else if (this.form.type === 2) {
                this.form.data = {
                    jigsaw_id: '',
                }
            } else if (this.form.type === 4) {
                this.form.data = {
                    url: '',
                }
            }
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/carousel').then(res => {
                    this.data = res.data.data.carouselList;
                }),
                axios.get(`/cms/v1/image`).then(res => {
                    this.image_list = res.data.data.imageList;
                }),
                axios.get('/cms/v1/collection').then(res => {
                    this.collection_list = res.data.data.collectionList;
                }),
                axios.get('/cms/v1/jigsaw').then(res => {
                    this.jigsaw_list = res.data.data.jigsawList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.form.data = deepcopy(item.data);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/carousel/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/carousel`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/carousel/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.image = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/carousel/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/carousel`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        validator(rule, value, callback) {
            if (/^#[A-Z0-9]{6}$/.test(value)) {
                callback();
            } else {
                callback('请选择或输入正确格式的颜色码');
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>