<template>
    <json-input mode="code" v-loading="loading" v-model="data"></json-input>
    <div>
        <el-button type="primary" :loading="loading" @click="submit">保存</el-button>
        <el-button type="info" :loading="loading" @click="init">重置</el-button>
    </div>
</template>

<script>
import JsonInput from "../base/JsonInput";
import axios from "ts-axios-new";

export default {
    name: "Config",
    components: {JsonInput},
    data() {
        return {
            loading: false, data: null,
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/config').then(res => {
                this.data = res.data.data;
                this.loading = false;
            })
        },
        submit() {
            this.loading = true;
            axios.post('/cms/v1/config', {data: this.data}).then(_ => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>