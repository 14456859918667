<template>
    <div>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="预览图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="激励" width="100" prop="video">
            <template #default="scope">
                {{ scope.row.video ? '是' : '否' }}
            </template>
        </el-table-column>
        <el-table-column label="上线日期" width="300" prop="online_date">
            <template #default="scope">
                <el-date-picker v-model="scope.row.online_date" value-format="YYYY-MM-DD" :disabled="loading"
                                @change="update(scope.row)" size="small"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :loading="loading" @click="openDialog(scope.row)" size="small">编辑</el-button>
                <el-button :loading="loading" type="danger" @click="remove(scope.row)" size="small">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="图片" prop="origin" :rules="[{required: true, message: '请上传原图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleUploaded('origin')"
                           accept="image/*" :data="{prefix: 'create'}" :onProgress="handleProgress"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.origin" :src="`${$root.env.CDN_URL}/${form.origin}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="激励视频" prop="video">
                <el-radio-group v-model="form.video" :disabled="loading">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="上线时间" prop="online_date">
                <el-date-picker v-model="form.online_date" value-format="YYYY-MM-DD"
                                :disabled="loading"></el-date-picker>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessageBox} from "element-plus";

export default {
    name: "CreateGallery",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            form: {
                origin: '',
                video: false,
                online_date: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/create/gallery').then(res => {
                this.data = res.data.data.galleryList;
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/create/gallery/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/create/gallery`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/create/gallery/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/create/gallery/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                });
            }).catch(_ => {
            })
        },
        handleProgress() {
            this.loading = true;
        },
        handleUploaded(name) {
            return res => {
                this.loading = false;
                this.form[name] = res.data.name
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>